import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { store } from './store';
import router from './router';

Vue.config.productionTip = false;

// Plugins
import FirebaseAuthPlugin from './plugins/firebase';
Vue.use(FirebaseAuthPlugin);

// Filters
import { snakeCaseToTitleCase, isoDate, trim, leftPad } from './filters';

Vue.filter('snakeCaseToTitleCase', snakeCaseToTitleCase);
Vue.filter('isoDate', isoDate);
Vue.filter('trim', trim);
Vue.filter('leftPad', leftPad);

store.commit('setAppVersion', process.env.PACKAGE_VERSION);

new Vue({
  vuetify,
  store,
  router,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  render: h => h(App),
}).$mount('#app');
