import { firestoreAction } from 'vuexfire';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import router from '../router';

export default {
  triggerEditMode({ commit }, id) {
    commit('setCurrentEditDocId', id);
    commit('setEditMode', true);
  },
  copyToHistory(store, { db, doc }) {
    const scopesRef = db.collection('scopes');

    scopesRef
      .doc(doc.id)
      .get()
      .then(doc => {
        return doc.ref
          .collection('history')
          .add({ ...doc.data(), edited: new Date() });
      });
  },

  /**
   * Push a new scope object to the scopes collection.
   *
   * @param {object} store - vuex context.
   * @param {object} arguments.db - vm db reference
   * @param {object} arguments.newScope - The newly created partial scope.
   */
  addNewScope(store, { db, newScope }) {
    const scopesRef = db.collection('scopes');

    //Application stores date as a single ISO yyyy-mm-dd string.
    //Database stores a date object.
    newScope.meetingDate = new Date(newScope.meetingDate);

    return scopesRef.add(newScope).then(() => {
      return 'done';
    });
  },

  /**
   * Add a focus object to an existing scope.
   */
  addFocus({ dispatch }, { db, focus }) {
    const newFocus = focus || { name: 'New Scope' };

    dispatch('updateFocus', {
      db,
      focusKey: new Date().getTime(),
      value: newFocus,
    });
  },

  /**
   * Swap two focus objects by key
   */
  swapFocus({ state }, { db, source, target }) {
    const destructureFocus = f => {
      const { key, ...focus } = f;
      return { key: `focus.${key}`, focus };
    };

    const targetFocus = destructureFocus(target);
    const sourceFocus = destructureFocus(source);

    const scopesRef = db.collection('scopes').doc(state.currentEditDocId);
    const batch = db.batch();

    batch.update(scopesRef, { [targetFocus.key]: sourceFocus.focus });
    batch.update(scopesRef, { [sourceFocus.key]: targetFocus.focus });

    return batch.commit();
  },

  /**
   * Switch two focus objects to exiting keys
   */
  switchFocus({ state, dispatch }, { db, sourceFocus, targetFocus, id }) {
    id = id || state.currentEditDocId;

    if (id) {
      dispatch('updateFocus', {
        db,
        id,
        focusKey: targetFocus.key,
        value: sourceFocus,
      });

      dispatch('updateFocus', {
        db,
        id,
        focusKey: sourceFocus.key,
        value: targetFocus,
      });
    }
  },

  /**
   * Switches the scope collection to be limited by projectId.
   *
   * @param {object} state - Data store state
   * @param {function} dispatch - submit vuex action
   */
  setScopeCollection({ state, dispatch }, { db }) {
    const scopesRef = db.collection('scopes');
    if (state.projectId) {
      const projectRef = scopesRef
        .where('deleted', '==', null)
        .where('project.key', '==', state.projectId);

      dispatch('bindScopesRef', {
        firestoreRef: projectRef,
        refName: 'scopes',
      });
    }
  },

  bindScopesRef: firestoreAction(
    async ({ bindFirestoreRef }, { firestoreRef, refName }) => {
      return await bindFirestoreRef(refName, firestoreRef);
    }
  ),

  unbindRef: firestoreAction(async ({ unbindFirestoreRef }, refName) => {
    return await unbindFirestoreRef(refName);
  }),

  removeFocus(context, { focusKey, doc, db }) {
    const scopesRef = db.collection('scopes');
    scopesRef
      .doc(doc.id)
      .get()
      .then(document => {
        const focus = { ...document.data().focus };
        delete focus[focusKey];
        return document.ref.update('focus', focus);
      });
  },

  async getAllUsers({ commit }) {
    const listAllUsers = await firebase
      .functions()
      .httpsCallable('listAllUsers');

    try {
      const { data: users } = await listAllUsers();
      commit('setAllUsers', users);
      return users;
    } catch (err) {
      console.log({ err });
    }
  },

  updateProjectDetails({ state, commit }) {
    commit(
      'setProjectName',
      state.selectedProject[0] && state.selectedProject[0].name
    );
  },

  async getFields() {},

  async updateAvailableEvents({ dispatch, getters }, { route, range }) {
    range = range || {
      start: new Date('2020-05-01'),
      end: new Date('2020-05-31'),
    };
    route = route || router.currentRoute.name;

    if (!route.includes('schedule')) return [];
    if (getters.projectsViewable.length <= 0) return [];

    const scopesRef = firebase.firestore().collection('scopes');
    // const viewableProjects = getters.projectsViewable.map((p) => p.projectId);

    const scheduledProjects = getters.getSelectedProjectsForSchedule;

    if (scheduledProjects.length > 0) {
      // All Scopes with Meeting in Range
      const scopesByMeetingRef = scopesRef
        .where('project.key', 'in', scheduledProjects)
        .where('meetingDate', '>=', range.start)
        .where('meetingDate', '<=', range.end)
        .where('deleted', '==', null)
        .orderBy('meetingDate');

      dispatch('bindScopesRef', {
        refName: 'scopesByMeetingDateRange',
        firestoreRef: scopesByMeetingRef,
      });

      // All Scopes with Upload in Range
      const scopesByUploadRef = scopesRef
        .where('project.key', 'in', scheduledProjects)
        .where('uploadDate', '>=', range.start)
        .where('uploadDate', '<=', range.end)
        .where('deleted', '==', null)
        .orderBy('uploadDate');

      dispatch('bindScopesRef', {
        refName: 'scopesByUploadDateRange',
        firestoreRef: scopesByUploadRef,
      });
    }
  },

  async updateAvailableProjects({ state, dispatch }, { route }) {
    const projectsRef = firebase.firestore().collection('projects');

    void route;
    if (route.includes('admin')) {
      await dispatch('bindScopesRef', {
        refName: 'projects',
        firestoreRef: projectsRef,
      });
    }

    const projectsRealOwnerRef = projectsRef.where(
      `roles.${state.userId}`,
      '==',
      'owner'
    );

    const projectsOwnerRef = state.isGrfn
      ? projectsRef
      : projectsRef.where(`roles.${state.userId}`, '==', 'owner');

    const projectsEditorRef = projectsRef.where(
      `roles.${state.userId}`,
      '==',
      'editor'
    );

    const projectsViewerRef = projectsRef.where(
      `roles.${state.userId}`,
      '==',
      'viewer'
    );

    await dispatch('bindScopesRef', {
      refName: 'projectsEditorDb',
      firestoreRef: projectsEditorRef,
    });
    await dispatch('bindScopesRef', {
      refName: 'projectsOwnerDb',
      firestoreRef: projectsOwnerRef,
    });
    await dispatch('bindScopesRef', {
      refName: 'projectsRealOwnerDb',
      firestoreRef: projectsRealOwnerRef,
    });
    await dispatch('bindScopesRef', {
      refName: 'projectsViewerDb',
      firestoreRef: projectsViewerRef,
    });
  },
  clearScopeId({ commit }) {
    commit('setScopeId', null);
  },
  updateFocus({ state }, { db, field, value, focusKey, id }) {
    id = id || state.currentEditDocId;

    if (id) {
      const scopesRef = db.collection('scopes');
      const newFocus = { [focusField({ focusKey, field })]: value };
      return scopesRef.doc(id).update(newFocus);
    }
  },
  updateFacts({ state }, { db, field, value }) {
    const id = state.currentEditDocId;
    const scopesRef = db.collection('scopes');
    const newFocus = { [field]: value };
    return scopesRef.doc(id).update(newFocus);
  },

  addUser({ dispatch, getters }, { project, role, email }) {
    const existingUsers = getters.getUsersInRole(project, role);
    const userIds = existingUsers.filter(Boolean).map(user => user.uid);

    const createUser = firebase.functions().httpsCallable('createUser');
    const user = {
      email: email,
      emailVerified: false,
      password: 'dummyPassword',
      displayName: email.split('@')[0],
      disabled: false,
    };

    return new Promise((resolve, reject) => {
      createUser({ user })
        .then(({ data }) => {
          const users = [...userIds, data.uid];
          return dispatch('setUsersInRole', { project, users, role });
        })
        .then(async user => {
          await dispatch('checkUserStatus');
          await dispatch('updateAvailableProjects', { route: 'admin' });
          await dispatch('updateProjectDetails');
          return user;
        })
        .then(user => resolve(user))
        .catch(err => {
          reject(err);
        });
    });
  },

  googleLogin(store, { auth }) {
    return new Promise((resolve, reject) => {
      auth
        .googleLogin()
        .then(user => {
          resolve(user);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  login(store, { auth, email, password }) {
    return new Promise((resolve, reject) => {
      auth
        .login(email, password)
        .then(user => {
          resolve(user.user);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  async logout({ commit, dispatch }, { auth }) {
    await dispatch('unbindRef', 'scopeFieldValues');
    await dispatch('unbindRef', 'config');
    await dispatch('unbindRef', 'selectedProject');
    await dispatch('unbindRef', 'scopesByMeetingDateRange');
    await dispatch('unbindRef', 'scopesByUploadDateRange');
    await dispatch('unbindRef', 'scopes');
    await dispatch('unbindRef', 'projects');
    await dispatch('unbindRef', 'projectsEditorDb');
    await dispatch('unbindRef', 'projectsOwnerDb');
    await dispatch('unbindRef', 'projectsRealOwnerDb');
    await dispatch('unbindRef', 'projectsViewerDb');
    await auth.logout();
    commit('LOGOUT');
  },

  checkUserStatus() {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(resolve, reject);
    });
  },

  deleteScope({ dispatch }, { db, doc }) {
    const scopesRef = db.collection('scopes');
    return scopesRef
      .doc(doc.id)
      .update('deleted', true)
      .then(async () => {
        return await dispatch('copyToHistory', { db, doc });
      });
  },

  setUsersInRole({ state }, { project, users, role }) {
    const projectsRef = firebase.firestore().collection('projects');

    const allUsersAsMap = state.allUsers.reduce((allUsers, user) => {
      allUsers[user.uid] = user;
      return allUsers;
    }, {});

    projectsRef
      .doc(project.id)
      .get()
      .then(projectDoc => {
        const currentRoles = { ...projectDoc.data().roles };
        const newRoles = Object.keys(currentRoles).reduce((roles, user) => {
          if (currentRoles[user] === role) return roles;
          if (!allUsersAsMap[user]) return roles;
          roles[user] = currentRoles[user];
          return roles;
        }, {});
        users.forEach(user => {
          newRoles[user] = role;
        });

        projectsRef.doc(project.id).update('roles', newRoles);
      });
  },
  setProjectsViewable({ state, commit }) {
    const projects = rolesToProjectsArray([
      ...state.projectsViewerDb,
      ...state.projectsEditorDb,
      ...state.projectsOwnerDb,
    ]);

    commit('setProjectsViewable', projects);
  },

  async updateUser({ commit }, { user }) {
    const P = user.user ? user.user : user;
    const {
      providerData: {
        0: { photoURL, displayName },
      },
    } = P;

    const token = await P.getIdTokenResult();

    await firebase.auth().currentUser.updateProfile({ photoURL, displayName });

    commit('updateUser', token);
    commit('updateUserPhoto', firebase.auth().currentUser.photoURL);
    commit('updateUserName', firebase.auth().currentUser.displayName);
  },

  async setActiveModelSet(
    { state, dispatch },
    { contributor, discipline, projectKey }
  ) {
    void state;
    const modelSetsRef = firebase.firestore().collection('models');

    dispatch('bindScopesRef', {
      refName: 'activeModelSet',
      firestoreRef: modelSetsRef
        .where('contributor', '==', contributor)
        .where(
          'discipline',
          '==',
          `${contributor.toLowerCase()}_${discipline.toLowerCase()}`
        )
        .where('projectKey', '==', projectKey),
    });
  },

  async setActiveMonitoringSet(
    { state, dispatch },
    { contributor, discipline, projectKey }
  ) {
    void state, contributor, discipline, projectKey;
    const monitoringSetProject = firebase.firestore().collection('monitoring');
    const query = monitoringSetProject
      .where('project.key', '==', projectKey)
      .limit(1);

    const projectRef = dispatch('bindScopesRef', {
      refName: 'activeMonitoringSet',
      firestoreRef: query,
    });

    projectRef.then(data => {
      const [doc] = data;

      let monitoringSetsRef = firebase
        .firestore()
        .collection(`monitoring/${doc.id}/results`);

      if (discipline) {
        monitoringSetsRef = monitoringSetsRef.where(
          'discipline',
          '==',
          discipline
        );
      }

      dispatch('bindScopesRef', {
        refName: 'activeMonitoringSet',
        firestoreRef: monitoringSetsRef,
      });
    });
  },
};

const focusField = ({ focusKey, field }) => {
  if (!field) return `focus.${focusKey}`;
  return `focus.${focusKey}.${field}`;
};

const rolesToProjectsArray = roles => {
  return Object.entries(
    roles.reduce((projects, { key, name }) => {
      projects[key] = name;
      return projects;
    }, {})
  ).map(([projectId, name]) => ({ ...{ projectId, name } }));
};
