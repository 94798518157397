import Vue from 'vue';
import VueRouter from 'vue-router';

import { store } from '../store';
import SignIn from '../views/SignIn.vue';

import firebase from 'firebase/app';

// import moment from "moment";
import dayjs from 'dayjs';

Vue.use(VueRouter);

const routes = [
  {
    path: '/models/:projectId',
    name: 'model-viewer',
    component: () => import('../views/ModelViewer.vue'),
    meta: { authRequired: false, title: 'Model Viewer' },
  },
  {
    path: '/monitoring/:projectId/',
    name: 'monitoring-view',
    component: () => import('../views/MonitoringView.vue'),
    meta: { authRequired: false, title: 'Monitoring' },
    redirect: { name: 'monitoring-data-quality' },
    children: [
      {
        name: 'monitoring-data-quality',
        path: 'data-quality',
        components: {
          dq: () => import('../components/DataQuality.vue'),
        },
        meta: {
          authRequired: false,
          title: 'Monitoring',
          tab: 'data-quality',
        },
      },
      {
        name: 'monitoring-baseline-coverage',
        path: 'baseline-coverage',
        components: {
          bc: () => import('../components/BaselineCoverage.vue'),
        },
        meta: {
          authRequired: false,
          title: 'Monitoring',
          tab: 'baseline-coverage',
        },
      },
    ],
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
    meta: { title: 'GRFN – Sign-in' },
  },
  {
    path: '/signout',
    name: 'signout',
    meta: { title: 'GRFN' },
    async beforeEnter(to, _from, next) {
      const v = new Vue();
      await store.dispatch('logout', { auth: v.$auth });
      next({ name: 'signin', query: { redirect: 'register' } });
    },
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('../views/ShowCalendar.vue'),
    meta: { title: 'Analysis Schedule', authRequired: true },
  },
  {
    path: '/insights/:projectId',
    name: 'insights',
    component: () => import('../views/ShowInsights.vue'),
    meta: { title: 'Insights', authRequired: true },
  },
  {
    path: '/register',
    name: 'register-root',
    component: () => import('../views/ShowRegister.vue'),
    meta: {
      title: 'Analysis Register',
      authRequired: true,
      configuration: 'register',
    },
  },
  {
    path: '/register/:projectId',
    name: 'register',
    component: () => import('../views/ShowRegister.vue'),
    meta: {
      title: 'Analysis Register',
      authRequired: true,
      configuration: 'register',
    },
  },
  {
    path: '/register/:projectId/:scopeId',
    name: 'scope',
    component: () => import('../views/ShowRegister.vue'),
    meta: {
      title: 'Analysis Register',
      configuration: 'register',
    },
  },
  {
    path: '/directory/:projectId',
    name: 'admin-directory',
    component: () => import('../views/Directory.vue'),
    meta: {
      title: 'Data Directories',
      authRequired: true,
      isGrfnRequired: true,
      configuration: 'admin-directory',
    },
  },

  {
    path: '/admin/:projectId/users',
    name: 'admin-users',
    component: () => import('../views/Admin.vue'),
    meta: {
      title: 'Project Participants',
      authRequired: true,
      isGrfnRequired: true,
      configuration: 'admin-users',
    },
  },
  {
    name: 'admin-projects',
    path: '/admin/:projectId/options',
    component: () => import('../views/AdminProjects.vue'),
    meta: {
      title: 'Project Options',
      authRequired: true,
      isGrfnRequired: true,
      configuration: 'admin-projects',
    },
  },
  {
    name: 'admin-uploads',
    path: '/admin/:projectId/uploads',
    component: () => import('../views/AdminUploads.vue'),
    meta: {
      title: 'File Uploads',
      authRequired: true,
      isGrfnRequired: true,
      configuration: 'admin-uploads',
    },
  },
  {
    path: '/scope/:projectId',
    name: 'bim-scope',
    redirect: { name: 'register' },
    component: () => import('../views/ShowRegister.vue'),
    meta: { title: 'BIM Scope' },
  },
  {
    path: '/issues/:projectId',
    name: 'issue-register',
    component: () => import('../views/ShowIssueRegister.vue'),
    meta: {
      title: 'Issue Register',
      authRequired: true,
      configuration: 'issue-register',
    },
  },
  {
    path: '/assets/:projectId',
    name: 'asset-register',
    component: () => import('../views/ShowIssueRegister.vue'),
    meta: {
      title: 'Issue Register',
      authRequired: true,
      configuration: 'issue-register',
    },
  },
  {
    path: '*',
    redirect: { name: 'register-root', params: { projectId: null } },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { projectId, focusDay, scopeId } = to.params;
  const { authRequired } = to.meta;
  const { name, fullPath } = to;

  store.commit('setScopeId', scopeId);
  store.commit('cancelEditMode');

  if (focusDay) {
    // let focusDayFormatted = new moment(focusDay).format("YYYY-MM-DD");
    const focusDayFormatted = dayjs(focusDay).format('YYYY-MM-DD');

    store.commit('setFocus', focusDayFormatted);
  } else {
    // store.commit("setFocus", new moment().format("YYYY-MM-DD"));
    store.commit('setFocus', dayjs().format('YYYY-MM-DD'));
  }

  let user;
  if (!store.getters.getUserId) user = await store.dispatch('checkUserStatus');

  if (!store.state.projectId || store.state.projectId !== projectId) {
    if (projectId) store.commit('setProjectId', projectId);
  }

  // if no auth required - proceed
  if (authRequired || user) {
    if (!store.getters.getUserId) {
      // check users auth status

      // if no user - sign in
      const signin = { name: 'signin', query: { redirect: fullPath } };
      if (!user) return next(signin);

      // set project and scope data and proceed
      await store.dispatch('updateUser', { user });
    }

    let availableProjects = store.getters.projectsViewable;

    if (availableProjects.length <= 0) {
      await store.dispatch('updateAvailableProjects', { route: name });
      await store.dispatch('updateProjectDetails');
      availableProjects = store.getters.projectsViewable;
    }

    if (
      projectId &&
      !availableProjects.some(project => project.projectId === projectId)
    ) {
      store.commit('setProjectId', null);
      return next('register-root');
    }

    if (name.includes('schedule')) {
      // await store.dispatch('updateAvailableEvents', {route: name});
    }

    if (
      projectId &&
      availableProjects.some(project => project.projectId === projectId)
    ) {
      const projectsRef = firebase.firestore().collection('projects');
      const configRef = firebase.firestore().collection('configuration');

      await store.dispatch('bindScopesRef', {
        refName: 'selectedProject',
        firestoreRef: projectsRef.where('key', '==', projectId).limit(1),
      });
      await store.dispatch('bindScopesRef', {
        refName: 'config',
        firestoreRef: configRef.limit(1),
      });
    }

    if (name === 'register-root' || to.name === 'register') {
      if (availableProjects.length === 1) {
        const firstProjectId = availableProjects[0].projectId;
        store.commit('setProjectId', firstProjectId);

        if (name === 'register-root') {
          return next({
            path: `register/${firstProjectId}`,
            params: { firstProjectId },
          });
        }
      }
    }

    if (name === 'admin-directory') {
      const directoryRef = firebase.firestore().collection('projects');
      await store.dispatch('bindScopesRef', {
        refName: 'scopeFieldValues',
        firestoreRef: directoryRef,
      });
    }

    if (name === 'admin-users') {
      if (store.state.allUsers.length === 0)
        await store.dispatch('getAllUsers');
      if (store.getters.adminProjects.length === 0)
        await store.dispatch('updateAvailableProjects', { route: name });
    }
  }

  return next();
});

export default router;
