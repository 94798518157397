export default {
  currentEditDocId: '',
  user: null,
  userPhoto: '',
  userName: '',
  userId: null,
  isGrfn: false,
  allUsers: [],
  scopes: [],
  sortProjects: 0,
  hideArchived: 3,
  projectNameDisplay: [2],
  scopeFieldValues: [],
  selectedProject: [],
  projectsOwnerDb: [],
  projectsRealOwnerDb: [],
  projectsViewerDb: [],
  projectsEditorDb: [],
  projectsViewable: [],
  projectsEditable: [],
  projectsOwned: [],
  scopeEditMode: false,
  scopeRef: undefined,
  scopesByMeetingDateRange: [],
  scopesByUploadDateRange: [],
  projectId: undefined,
  projectRequiresApproval: undefined,
  projectName: undefined,
  scopeId: undefined,
  projects: [],
  scheduledProjectKeys: [],
  focusDay: null,
  defaultScope: {
    deleted: null,
    focus: { [new Date().getTime()]: { name: 'New Focus' } },
    summary: 'Analysis Scope TBC',
    project: null,
    number: 0,
    meetingDate: null,
    stream: 'Design',
    approved: false,
  },
  config: [],
  packageVersion: '1.0.0',
  devMode: false,
  activeModelSet: [],
  projectModels: [],
  activeMonitoringSet: [],
  activeMonitoringProject: [],
};
