import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyALfiVqqb41bHxEcgC4qsanHz03q7M0Bbw',
  authDomain: 'dev.grfn.io',
  databaseURL: 'https://grfn-vue-nuxt.firebaseio.com',
  projectId: 'grfn-vue-nuxt',
  storageBucket: 'grfn-vue-nuxt.appspot.com',
  messagingSenderId: '30169129297',
  appId: '1:30169129297:web:2f11e40321879cbc',
};

export default {
  install: Vue => {
    firebase.initializeApp(config);
    const firestore = firebase.firestore();

    const googleProvider = new firebase.auth.GoogleAuthProvider();
    const functions = firebase.functions();

    const storage = bucket => {
      return bucket ? firebase.app().storage(bucket) : firebase.storage();
    };

    googleProvider.setCustomParameters({
      prompt: 'select_account',
    });

    firestore.settings({
      merge: true,
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    });

    firestore.enablePersistence({
      synchronizeTabs: true,
    });

    Vue.prototype.$db = firestore;
    Vue.prototype.$functions = functions;
    Vue.prototype.$storage = storage;

    Vue.prototype.$auth = {
      googleLogin: async () => {
        return await firebase.auth().signInWithPopup(googleProvider);
      },
      // login: async (username, pass) => {
      //   return await auth.signInWithEmailAndPassword(username, pass);
      // },
      logout: async () => {
        await firebase.auth().signOut();
      },
    };
  },
};
