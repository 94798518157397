<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      loggedIn: false,
    };
  },
  computed: {
    ...mapState(['userId']),
    nextRoute() {
      return this.$route.query.redirect || '/';
    },
  },
  watch: {},
  mounted() {},
  methods: {
    handleLoginButton() {
      const vm = this;
      this.$store
        .dispatch('googleLogin', { auth: vm.$auth })
        .then(user => {
          this.loggedIn = true;
          return this.$store.dispatch('updateUser', { user });
        })
        .then(() => {
          this.$router.replace(this.nextRoute);
        });
    },
  },
};
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-progress-linear v-if="loggedIn" indeterminate color="primary" />
        <v-col v-else md="7">
          <v-card>
            <v-card-title>
              <h2>Authentication & Privacy</h2>
            </v-card-title>
            <v-card-text>
              <p>
                The GRFN Analysis and Insights Platform requires that you login
                with an approved account.
              </p>
              <p>
                We use Google as our authentication trust provider and do not
                store any personal information or passwords. If you have not
                already done so, you can associate your existing email persona
                with a Google Id.
              </p>
              <p>
                GRFN will associate whatever id you provide you should have
                access to.
              </p>
              <p>
                GRFN will not record any information related to your id that is
                not related to use of the platform undertaken while
                authenticated.
              </p>
              <p>
                Once authenticated we will keep that id signed-in until it is no
                longer associated with an active project or on any update to
                this policy.
              </p>
            </v-card-text>
            <v-card-actions>
              <form @submit.prevent="handleLoginButton">
                <v-btn dark text color="action" @click="handleLoginButton">
                  Signin with Google
                </v-btn>
                <v-btn
                  color="secondary"
                  href="https://accounts.google.com/SignUpWithoutGmail"
                  target="_blank"
                  text
                >
                  Associate a Google Id
                </v-btn>
              </form>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
