<script>
import Vuex, { mapMutations } from 'vuex';
import distinctColors from 'distinct-colors';

// ProjectSelector is used in the NavigationDrawer component
export default {
  name: 'ProjectSelector',
  props: {
    projects: { type: Array, default: () => [] },
    scopeProject: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentProject: null,
      currentlySelected: [],
      modes: ['Team', 'Live', 'Paused', undefined, 'Archived'],
    };
  },
  computed: {
    ...Vuex.mapState(['projectId', 'userId']),
    teamProjects() {
      return [...this.$store.getters.teamProjects];
    },
    hideArchivedSetting: {
      get() {
        return this.$store.state.hideArchived;
      },
      set(value) {
        this.$store.commit('setHideArchived', value);
      },
    },
    mode() {
      return this.modes[this.hideArchivedSetting];
    },
    projectNameDisplaySetting: {
      get() {
        return this.$store.state.projectNameDisplay;
      },
      set(values) {
        this.$store.commit('setProjectNameDisplay', values);
      },
    },
    sortProjectsSetting: {
      get() {
        return this.$store.state.sortProjects;
      },
      set(value) {
        this.$store.commit('setProjectSort', value);
      },
    },
    colors() {
      return [
        ...distinctColors({
          count: this.projects.length,
          lightMin: 15,
          lightMax: 50,
          chromaMin: 25,
        }),
      ];
    },
    projectNameDisplay() {
      const mode = this.projectNameDisplaySetting,
        sort = this.sortProjectsSetting,
        hide = this.hideArchivedSetting;

      const displayProjectSetting = project => {
        const showTeam =
          this.teamProjects.filter(p => {
            return p.code === project.code && p.projectId === project.projectId;
          }).length > 0;
        const showOnlyLive = project.live && !project.archived;
        const hideLiveHideArchived = !project.live && !project.archived;
        const showAll = true;
        const showArchived = !project.live && project.archived;

        const modes = [
          showTeam,
          showOnlyLive,
          hideLiveHideArchived,
          showAll,
          showArchived,
        ];

        return modes[hide];
      };

      return (
        [...this.projects]
          // .filter((p) => {
          //   return hide == 0 ? true : hide == 2 ? p.archived : !p.archived;
          // })
          .filter(displayProjectSetting)
          .map(p => {
            const code = mode.includes(0) ? p.code : '',
              key = mode.includes(1) ? p.projectId : '',
              name = mode.includes(2) ? p.name : '';

            let displayName = [
              code ? code : '',
              key ? key : '',
              name ? name : '',
            ];

            if (mode.length === 0) {
              displayName = [p.name];
            }

            p.displayName = displayName.filter(p => p).join(' ');
            return p;
          })
          .sort((projectA, projectB) => {
            const { displayName: a } = projectA;
            const { displayName: b } = projectB;

            const direction = sort ? -1 : 1;

            return a === b ? 0 : a > b ? direction : -direction;
          })
      );
    },
    isRegisterRoute() {
      return (
        this.currentRoute === 'register' ||
        this.currentRoute === 'register-root' ||
        this.currentRoute === 'scope' ||
        this.currentRoute.includes('register')
      );
    },

    isAdminRoute() {
      return this.currentRoute.includes('admin');
    },
    isToolsRoute() {
      return this.currentRoute.includes('tools');
    },
    isModelRoute() {
      return this.currentRoute.includes('model');
    },
    isMonitoringRoute() {
      return this.currentRoute.includes('monitoring');
    },
    isScheduleRoute() {
      return this.currentRoute === 'schedule';
    },
    isInsightsRoute() {
      return this.currentRoute === 'insights';
    },
    selected: {
      get: function () {
        if (this.multiple) {
          return typeof this.currentlySelected === 'string'
            ? [this.currentlySelected]
            : [...this.currentlySelected];
        }
        if (!this.$store) return this.projectId || null;
        return this.$store.state.projectId || null;
      },
      set: function (value) {
        if (!value) return;
        if (typeof value === 'string') {
          this.$store.commit('setScheduledProjects', value);
          return (this.currentlySelected = value);
        }

        this.currentlySelected = [...value];
        this.$store.commit('setScheduledProjects', this.currentlySelected);

        if (value.length === 1)
          return this.$store.commit('setProjectId', value[0]);
        if (value.length === 0) this.currentlySelected = [];
        this.$store.commit('setProjectId', null);
      },
    },
    currentRoute() {
      return this.$route.name;
    },
    multiple() {
      return this.isScheduleRoute;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
  },
  mounted() {
    this.selected = this.$store.state.projectId;
  },
  methods: {
    ...mapMutations([]),
    colorSwatch(index) {
      return this.colors[index].darken().brighten().saturate().hex();
    },
    switchView(projectId) {
      if (projectId) {
        this.$store
          .dispatch('clearScopeId')
          .then(() => {
            this.$router.push({
              name: this.$route.meta.configuration,
              params: {
                projectId,
                project: this.selectedProject,
              },
            });
          })
          .then(() => {
            this.selected = projectId;
            this.$store.commit('setProjectId', projectId);
          });
      }
    },
    selectProject(projectId) {
      if (this.multiple) return;

      if (projectId === this.projectId) {
        this.selected = projectId;
        return;
      }

      if (
        this.isRegisterRoute ||
        this.isAdminRoute ||
        this.isToolsRoute ||
        this.isModelRoute ||
        this.isMonitoringRoute ||
        this.isIssueRegisterRoute ||
        this.isInsightsRoute
      ) {
        return this.switchView(projectId);
      }

      this.$store.commit('setProjectId', projectId);
    },
  },
};
</script>

<template>
  <div class="project-list-container">
    <v-divider
      v-if="$route.name && !$route.name.includes('sign') && projectId"
    />
    <div class="header-outer">
      <div v-if="projects.length > 1" class="text-overline pt-4 pl-4">
        <v-hover v-slot="{ hover }" open-delay="500" close-delay="500">
          <div class="header-inner content">
            <div class="expandable">
              Project List<span
                v-if="projectNameDisplay.length > 1"
                class="listMode"
                >{{ mode }}</span
              >
            </div>
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex v-card--reveal options pa-2 ml-n2 options"
              >
                <v-row>
                  <v-col cols="7" class="ma-0 py-2">
                    <div class="text-caption text-capitalize">
                      Project Names
                    </div>
                    <v-btn-toggle
                      v-model="projectNameDisplaySetting"
                      class="name"
                      multiple
                      dense
                      mandatory
                    >
                      <v-btn x-small class="text-uppercase" title="GRFN Code"
                        >12A</v-btn
                      >
                      <v-btn x-small class="text-uppercase" title="Project Code"
                        >ABC</v-btn
                      >
                      <v-btn
                        x-small
                        class="text-capitalize"
                        title="Project Name"
                        >Name</v-btn
                      >
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="3" class="ma-0 py-2">
                    <div class="text-caption text-capitalize">Sort</div>
                    <v-btn-toggle
                      v-model="sortProjectsSetting"
                      mandatory
                      dense
                      class="sort"
                    >
                      <v-btn x-small title="Sort Ascending">
                        <v-icon small>mdi-sort-ascending</v-icon>
                      </v-btn>
                      <v-btn x-small title="Sort Descending">
                        <v-icon small>mdi-sort-descending</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="8" class="ma-0 py-0 display">
                    <div class="text-caption text-capitalize">
                      Project Display
                    </div>
                    <v-btn-toggle
                      v-model="hideArchivedSetting"
                      mandatory
                      dense
                      class="display"
                    >
                      <v-btn x-small title="Show Team Projects">
                        <v-icon x-small>mdi-account-box</v-icon>
                      </v-btn>
                      <v-btn x-small title="Show Live">
                        <v-icon x-small>mdi-layers</v-icon>
                      </v-btn>
                      <v-btn x-small title="Show Paused">
                        <v-icon x-small>mdi-layers-off</v-icon>
                      </v-btn>
                      <v-btn x-small title="Show All">
                        <v-icon x-small>mdi-layers-triple</v-icon>
                      </v-btn>
                      <v-btn x-small title="Show Archived">
                        <v-icon x-small>mdi-layers-outline</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </div>
        </v-hover>
      </div>
      <div
        v-else-if="$route.name && !$route.name.includes('sign')"
        class="text-overline pt-4 pl-4"
      >
        Project
      </div>
      <v-list dense flat class="projectListFrame overflow-y-auto content">
        <v-list-item-group aria-label="Project List">
          <v-list-item v-if="!projects.length" role="option">{{
            scopeProject && scopeProject.name
          }}</v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-model="selected"
          :multiple="multiple"
          class="project-list"
          aria-label="Project List"
        >
          <div
            v-for="(project, index) in projectNameDisplay"
            :key="project.projectId"
          >
            <v-hover v-slot="{ hover }">
              <v-list-item
                :value="project.projectId"
                :ripple="false"
                :disabled="project.projectId === selected"
                :class="{ 'action--text': project.projectId === selected }"
                :color="colorSwatch(index)"
                role="option"
                @click.stop="selectProject(project.projectId)"
              >
                <template #default="{}">
                  <span v-if="isScheduleRoute" class="pr-1 pb-1">
                    <v-icon v-if="selected.indexOf(project.projectId) < 0" small
                      >mdi-circle-outline</v-icon
                    >
                    <v-icon
                      v-if="selected.indexOf(project.projectId) >= 0"
                      small
                      >mdi-circle-slice-8</v-icon
                    >
                  </span>
                  <v-list-item-content>
                    <v-list-item-title v-text="project.displayName" />
                  </v-list-item-content>
                  <v-list-item-action v-if="isScheduleRoute">
                    <v-btn
                      v-show="hover"
                      :ripple="false"
                      text
                      small
                      max-height="16px"
                      max-width="16px"
                      @click.stop="switchView(project.projectId)"
                    >
                      <v-icon color="default" small
                        >mdi-arrow-right-thick</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-hover>
          </div>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<style scoped lang="scss">
.expandable {
  cursor: context-menu;

  & + .options {
    background-color: #eee;
  }
}

.listMode {
  text-transform: lowercase;
  margin-left: 0.2rem;
  opacity: 0.5;

  &:not(:empty) {
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }
}

.project-list-container {
  // position: absolute;
  // max-height: calc(100vh - 620px);
  // min-height: 0;
  min-height: 88px;
  display: flex;
  width: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  height: 100%;
}

.project-list {
  background-color: transparent;
  overflow-y: auto;
  flex-grow: 1;
  min-height: 0;
}

.v-application {
  .projectListFrame {
    // max-height: calc(100vh - 660px);
    min-height: 88px;
    // overflow-y: hidden !important;

    border: none;

    &:hover {
      overflow-y: auto !important;

      &::-webkit-scrollbar-thumb {
        background-color: var(--v-primary-base);
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f5f5f5;
    }
  }
}
.name,
.display {
  position: relative;
  top: -3px;
}

.header-outer {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  min-height: 0;
}
</style>

<style>
.v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
