<script>
import { mapState, mapGetters } from 'vuex';
import ProjectSelector from '../components/ProjectSelector.vue';

export default {
  name: 'TopBar',
  components: {
    ProjectSelector,
  },
  data() {
    return {
      group: undefined,
      showMenu: false,
      drawer: this.$route.name && !this.$route.name.includes('sign'),
      route: [],
      configurationsExpanded: false,
    };
  },
  computed: {
    ...mapState(['projectId', 'userId', 'appVersion', 'devMode']),
    ...mapGetters(['user']),
    userName() {
      return this.$store.state.userName;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    scopes() {
      return this.$store.state.scopes;
    },
    availableProjects() {
      return [...this.$store.getters.projectsViewable].sort(
        this.dynamicSort('projectId')
      );
    },
    isGrfn() {
      return this.$store.state.isGrfn;
    },
    registerPath() {
      if (this.$route.name === 'register-root' || !this.projectId) {
        return { name: 'register-root', params: {} };
      }
      return this.projectId
        ? {
            name: 'register',
            params: { projectId: this.projectId },
          }
        : { name: 'register-root', params: {} };
    },
    modelPath() {
      return {
        name: 'model-viewer',
        params: { projectId: this.projectId || 'DMO' },
      };
    },
    monitoringPath() {
      return {
        name: 'monitoring-view',
        params: { projectId: this.projectId || 'DMO' },
      };
    },
    adminPath() {
      return {
        users: {
          name: 'admin-users',
          params: { projectId: this.projectId },
        },
        directory: {
          name: 'admin-directory',
          params: { projectId: this.projectId },
        },
        projects: {
          name: 'admin-projects',
          params: { projectId: this.projectId },
        },
        uploads: {
          name: 'admin-uploads',
          params: { projectId: this.projectId },
        },
      };
    },
    adminPath_Users() {
      return { name: 'admin-users', params: {} };
    },
    assetPath() {
      return { name: 'assets', params: { projectId: this.projectId } };
    },
    schedulePath() {
      return { name: 'schedule', params: { projectId: this.projectId } };
    },
    insightsPath() {
      return this.projectId
        ? { name: 'insights', params: { projectId: this.projectId } }
        : { name: 'register-root', params: {} };
    },
    assetsPath() {
      return this.projectId
        ? { name: 'asset-register', params: { projectId: this.projectId } }
        : { name: 'register-root', params: {} };
    },
    issuesPath() {
      return this.projectId
        ? {
            name: 'issue-register',
            params: { projectId: this.projectId },
          }
        : { name: 'register-root', params: {} };
    },
    scopePath() {
      return {
        name: 'bim-scope',
        params: { projectId: this.projectId || 'DMO' },
      };
    },
    uploadPath() {
      return {
        name: 'uploads',
        params: { projectId: this.projectId || 'DMO' },
      };
    },
    projectId() {
      return this.$store.state.projectId;
    },
    selectedProject() {
      return (
        this.$store.state.selectedProject &&
        this.$store.state.selectedProject[0]
      );
    },
    hasAssets() {
      return this.selectedProject && this.selectedProject.assets;
    },
    hasInsights() {
      return this.selectedProject && this.selectedProject.new_insights;
    },
    hasModels() {
      return this.selectedProject && this.selectedProject.hasModels;
    },
    hasMonitoring() {
      return this.selectedProject && this.selectedProject.hasMonitoring;
    },
    hasIssues() {
      return this.selectedProject && this.selectedProject.issues;
    },
    scopeId: {
      get() {
        return this.$route.params.scopeId;
      },
    },
    userId: {
      get() {
        return this.$store.state.userId;
      },
    },
    projectName() {
      return (
        this.$store.state.selectedProject[0] &&
        this.$store.state.selectedProject[0].name
      );
    },
    routeTitle() {
      const { title } = this.$route.meta;
      return title;
    },
    logoColor() {
      // let logoColor = this.$vuetify.theme.themes.light.grfn;
      // return logoColor.replace('#','%23');
      return '%23000000';
    },
  },
  mounted() {},
  created() {},
  methods: {
    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder === -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      };
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleLoginButton() {
      const vm = this;
      this.$store.dispatch('googleLogin', { auth: vm.$auth }).then(user => {
        this.$store.commit('updateUser', { user });
      });
    },
    cancelAnimation() {
      console.log('cancelling');
      this.$root.$emit('cancelAnimation');
    },
  },
};
</script>

<template>
  <div>
    <!-- TOP TOOLBAR -->
    <v-app-bar app clipped-left flat class="white">
      <v-app-bar-nav-icon
        role="button"
        aria-label="Application Drawer"
        @click="drawer = !drawer"
      />
      <v-toolbar-title>{{ routeTitle }}</v-toolbar-title>
      <v-spacer />
      <v-img
        :src="`data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 83.843 23.895' style='enable-background:new 0 0 83.843 23.895;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath style='fill:${logoColor};' d='M19.873,22.617V10.91h-9.027v3.223h5.231v6.221c-1.435,0.414-2.903,0.527-4.401,0.527 c-5.328,0-7.496-4.229-7.496-9.015c0-4.306,1.85-8.677,6.793-8.677c2.553,0,4.371,1.053,4.754,3.701h4.051 C19.363,1.976,15.63,0,11.165,0C3.987,0,0,5.199,0,12.026c0,7.179,4.146,11.868,11.516,11.868 C14.323,23.895,17.13,23.352,19.873,22.617'%3E%3C/path%3E%3C/g%3E%3Cpolygon style='fill:${logoColor};' points='61.276,3.763 61.276,0.509 46.794,0.509 46.794,23.574 50.687,23.574 50.687,14.053 60.735,14.053 60.735,10.831 50.687,10.831 50.687,3.763 '%3E%3C/polygon%3E%3Cpolygon style='fill:${logoColor};' points='83.843,23.574 83.843,0.51 80.333,0.51 80.333,19.811 70.126,0.51 64.704,0.51 64.704,23.574 68.212,23.574 68.212,4.274 78.419,23.574 '%3E%3C/polygon%3E%3Cg%3E%3Cpath style='fill:${logoColor};' d='M37.895,7.308c0,3.303-2.775,3.575-5.711,3.575h-2.902V3.81h3.541 C35.564,3.81,37.895,4.336,37.895,7.308 M41.977,7.4c0-2.265-0.925-4.369-2.872-5.647C37.32,0.605,35.183,0.51,33.141,0.51h-7.814 v23.066h3.955v-9.443h3.381c0.244,0,0.49-0.006,0.736-0.01l5.002,9.453h3.94l-5.256-9.864c0.253-0.068,0.512-0.127,0.746-0.217 C40.445,12.473,41.977,10.176,41.977,7.4'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E`"
        class="mx-2"
        height="1.35rem"
        max-width="4.75rem"
        contain
      />
    </v-app-bar>

    <!-- BEGIN NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-model="drawer"
      style="padding-right: 10px"
      app
      clipped
      disable-resize-watcher
      mobile-breakpoint="sm"
      ><template id="main-options" slot="prepend">
        <v-list nav shaped flat dense>
          <v-list-item-group
            v-model="route"
            active-class="action--text"
            aria-label="Analysis Register Options"
          >
            <v-list-item
              v-if="projectId && availableProjects.length"
              id="register-link"
              :to="registerPath"
              aria-label="Show Register"
              role="option"
              @click.native="cancelAnimation"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Analysis Register</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item
              v-if="availableProjects.length"
              id="schedule-link"
              :to="schedulePath"
              role="option"
              aria-label="Show Schedule"
              @click.native="cancelAnimation"
            >
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Schedule</v-list-item-title>
            </v-list-item> -->

            <v-list-item
              v-if="projectId && availableProjects.length"
              id="issues-link"
              :to="issuesPath"
              :disabled="projectId === null || !hasIssues"
              role="option"
              aria-label="Show Issue Register"
              @click.native="cancelAnimation"
            >
              <v-list-item-icon>
                <v-icon>mdi-clipboard-text</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Issue Register</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="projectId && availableProjects.length"
              id="assets-link"
              :to="assetsPath"
              :disabled="projectId === null || !hasAssets"
              role="option"
              aria-label="Show Asset Register"
              @click.native="cancelAnimation"
            >
              <v-list-item-icon>
                <v-icon>mdi-barcode</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Asset Register</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="projectId && availableProjects.length"
              id="insights-link"
              :disabled="!hasInsights"
              void:href="hasInsights && selectedProject.insights"
              :to="insightsPath"
              role="option"
              void:target="_blank"
              exact
              aria-label="Launch Insights"
              rel="noopener"
            >
              <v-list-item-icon>
                <v-icon :disabled="!isGrfn || !hasInsights"
                  >mdi-chart-bell-curve</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title>Insights</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item
              v-if="devMode"
              id="models-link"
              :to="modelPath"
              :disabled="!hasModels"
              aria-label="Show Models"
              role="option"
              ><v-list-item-icon>
                <v-icon :disabled="!isGrfn || !hasModels"
                  >mdi-target-variant</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title>Models</v-list-item-title>
            </v-list-item> -->

            <!-- <v-list-item
              v-if="devMode"
              id="monitoring-link"
              :to="monitoringPath"
              :disabled="!hasMonitoring"
              aria-label="Show Monitoring"
              role="option"
              ><v-list-item-icon>
                <v-icon :disabled="!isGrfn || !hasMonitoring"
                  >mdi-quality-high</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title>Monitoring</v-list-item-title>
            </v-list-item> -->
          </v-list-item-group>
        </v-list></template
      >
      <template id="project-selector" slot="default">
        <ProjectSelector
          :projects="availableProjects"
          :scope-project="scopes[0] && scopes[0].project"
        />
      </template>
      <template
        v-if="
          $route.name &&
          !$route.name.includes('sign') &&
          availableProjects.length
        "
        id="configuration"
        slot="append"
      >
        <template v-if="isGrfn" id="configuration">
          <div class="configurationDrawer">
            <v-divider />
            <v-spacer />
            <div class="text-overline pt-4 pl-4">
              Configuration
              <span @click="configurationsExpanded = !configurationsExpanded"
                ><v-icon v-if="configurationsExpanded">mdi-chevron-down</v-icon
                ><v-icon v-else>mdi-chevron-up</v-icon></span
              >
            </div>

            <v-list v-show="configurationsExpanded" nav shaped flat dense>
              <v-list-item-group
                v-model="group"
                active-class="action--text"
                aria-label="Configuration Options"
              >
                <v-list-item
                  v-if="projectId"
                  :to="adminPath.directory"
                  router
                  role="option"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-book-alphabet</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Directories</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="projectId"
                  :to="adminPath.users"
                  router
                  role="option"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-multiple</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Participants</v-list-item-title>
                </v-list-item>
                <v-list-item :to="adminPath.projects" router role="option">
                  <v-list-item-icon>
                    <v-icon>mdi-tune</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Projects</v-list-item-title>
                </v-list-item>
                <v-list-item :to="adminPath.uploads" router role="option">
                  <v-list-item-icon>
                    <v-icon>mdi-upload</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Uploads</v-list-item-title>
                </v-list-item>
                <v-list-item :to="adminPath.app" router disabled role="option">
                  <v-list-item-icon>
                    <v-icon disabled>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Application</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </template>
        <v-list dense>
          <v-list-item>
            <!-- <v-chip outlined v-on="on" v-if="userName"> -->
            <v-avatar size="24" left>
              <v-img :src="userPhoto" />
            </v-avatar>
            <span class="font-weight-light text-caption text-no-wrap pl-3">{{
              userName
            }}</span>
            <!-- </v-chip> -->
          </v-list-item>

          <v-list-item-group
            v-model="group"
            active-class="action--text"
            aria-label="Account"
          >
            <v-list-item
              :to="{
                name: 'signout',
                params: { projectId: null, scopeId: null },
              }"
              router
              role="option"
            >
              <v-list-item-icon>
                <v-icon disabled>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<style>
.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  background-color: hsla(0, 0%, 100%, 0.75);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.v-navigation-drawer__border {
  display: none;
}

.v-toolbar__title {
  font-weight: 500;
}

.v-navigation-drawer .v-list-item .v-list-item__title {
  font-weight: 400;
}

.v-navigation-drawer .project-list-container .v-list-item .v-list-item__title {
  font-weight: 400;
}

.v-navigation-drawer
  .project-list-container
  .v-list-item
  .v-list-item__title::before {
  content: '・';
  /* padding-right: 0.125em; */
  margin-left: -0.25em;
}

.v-application .overline,
.v-application .title,
.v.main .v-main__wrap .title {
  font-weight: 500 !important;
}
</style>
