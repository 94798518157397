import firebase from 'firebase/app';
import 'firebase/auth';
import { difference } from 'sets';
export default {
  projectId: state => {
    return state.projectId;
  },
  scopeFields: state => {
    const scopeFields = state.scopeFieldValues.reduce((set, project) => {
      Object.keys(project.fieldValues || {}).forEach(field => set.add(field));
      return set;
    }, new Set());

    const usedFields = state.scopeFieldValues.reduce((set, project) => {
      Object.keys(project.usedFieldValues || {}).forEach(field =>
        set.add(field)
      );
      return set;
    }, new Set());

    const pastValuesOnly = difference(usedFields, scopeFields);

    return { scopeFields, usedFields, pastValuesOnly };
  },
  projectFields: state => () => {
    let factsFields = state.factsFields || [];
    let focusFields = state.focusFields || [];

    if (!state.projectFields) return { factsFields, focusFields };

    factsFields = [...factsFields, ...(state.projectFields.factFields || [])];
    focusFields = [...focusFields, ...(state.projectFields.focusFields || [])];

    return { factsFields, focusFields };
  },
  defaultScope: state => {
    return state.defaultScope;
  },
  newScope: state => {
    return {
      ...state.defaultScope,
      project: {
        key: state.projectId,
        name:
          (state.selectedProject[0] && state.selectedProject[0].name) || null,
      },
      meetingDate: new Date(),
      number: state.scopes.reduce((number, scope) => {
        if (number <= scope.number) return scope.number + 1;
        return number;
      }, 1),
    };
  },
  currentUser() {
    return firebase.auth().currentUser;
  },
  allUsers: state => {
    return state.allUsers.map(({ displayName, email, uid, customClaims }) => ({
      name: displayName,
      email,
      uid,
      grfn: Boolean(customClaims && customClaims.isGrfn),
    }));
  },
  available: state => field => {
    return state.config[field];
  },
  projectsViewable: state => {
    return rolesToProjectsArray([
      ...state.projectsViewerDb,
      ...state.projectsEditorDb,
      ...state.projectsOwnerDb,
    ]);
  },
  adminProjects: state => {
    const administratableProjects = deDupeArray(
      [
        ...state.projectsViewerDb,
        ...state.projectsEditorDb,
        ...state.projectsOwnerDb,
      ],
      'key'
    );

    return administratableProjects;
  },
  teamProjects: state => {
    return rolesToProjectsArray([
      ...state.projectsViewerDb,
      ...state.projectsEditorDb,
      ...state.projectsRealOwnerDb,
    ]);
  },
  projectsEditable: state => {
    return rolesToProjectsArray([
      ...state.projectsEditorDb,
      ...state.projectsOwnerDb,
    ]);
  },
  selectedProject: state => {
    return rolesToProjectsArray([
      ...state.projectsViewerDb,
      ...state.projectsEditorDb,
      ...state.projectsOwnerDb,
    ]).filter(p => {
      return p.projectId === state.projectId;
    });
  },
  projectsOwned: state => {
    return rolesToProjectsArray([...state.projectsOwnerDb]);
  },
  getUsersInRole: state => (project, role) => {
    const allUsersAsMap = state.allUsers.reduce((allUsers, user) => {
      allUsers[user.uid] = user;
      return allUsers;
    }, {});

    const usersInRole = Object.keys(project.roles)
      .filter(user => {
        return project.roles[user] === role;
      })
      .map(userKey => {
        if (allUsersAsMap[userKey]) {
          const { uid, email, displayName } = allUsersAsMap[userKey];
          return { uid, email, displayName };
        }
        return;
      });

    return usersInRole.filter(Boolean);
  },
  getUserId: state => {
    return state.userId;
  },
  registerPhoto: () => {
    return firebase.auth().currentUser['photoURL'];
  },
  providerPhoto: () => {
    return firebase.auth().currentUser['providerData'][0]['photoURL'];
  },
  getSelectedProjectsForSchedule: state => {
    return state.scheduledProjectKeys;
  },
  getProjectConfiguration: (state, getters) => {
    if (!state.selectedProject[0]) return {};
    const { projectId: key, configuration: projectConfig = {} } = {
      ...state.selectedProject[0],
    };

    const appConfig = getters.appConfig;

    // const { analysisTypes: appTypes, streams: appStreams } = appConfig;
    // const {
    //   analysisUnits: projectStreams,
    //   analysisTypes: projectTypes = appConfig.analysisTypes,
    // } = projectConfig;
    let { analysisUnits, analysisTypes = appConfig.analysisTypes } =
      projectConfig;

    // console.log({ appTypes, appStreams });
    // console.log({ projectTypes, projectStreams });

    const { streamsTitle = 'Stream', configMode = 'replace' } = projectConfig;

    if (configMode === 'extends') {
      const extendedUnits = new Set([...analysisUnits, ...appConfig.streams]);
      analysisUnits = [...extendedUnits.values()].sort();
      const extendedTypes = new Set([
        ...analysisTypes,
        ...appConfig.analysisTypes,
      ]);
      analysisTypes = [...extendedTypes.values()].sort();
    }

    return { key, analysisUnits, analysisTypes, streamsTitle, configMode };
  },
  appConfig: state => {
    return state.config[0] || {};
  },
  appVersion: state => {
    return state.packageVersion;
  },
};

const rolesToProjectsArray = roles => {
  // roles array will be for the logged in user
  const projectList = roles.reduce(
    (projects, { key: projectId, code, name, archived, live }) => {
      // omit duplicates by adding to a map;
      projects.set(projectId, {
        projectId,
        code,
        name,
        archived: Boolean(archived),
        live: Boolean(live),
      });
      return projects;
    },
    new Map()
  );
  return [...projectList.values()];
};

const deDupeArray = (projects, key) => {
  const p = projects.reduce((map, project) => {
    map.set(project[key], project);
    return map;
  }, new Map());

  return [...p.values()];
};
