export default {
  themes: {
    light: {
      default: '#222222',
      grfn: '#fafafa',
      primary: '#000000',
      complementary: '#11aaaa',
      warning: '#aaaa44',
      calltoaction: '#11aa11',
      success: '#44aa44',
      positive: '#347613',
      analagous: '#aa7744',
      covid: '#ecad8c',
      secondary: '#aa4444',
      negative: '#ff4444',
      action: '#991111',
      'dark-red': '#661111',
    },
  },
  options: {
    customProperties: true,
  },
};
