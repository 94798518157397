<script>
import NavigationDrawer from './components/NavigationDrawer.vue';
import Vuex from 'vuex';

export default {
  name: 'App',
  components: {
    NavigationDrawer,
  },
  data() {
    return {
      footer: {
        inset: true,
      },
    };
  },
  computed: {
    ...Vuex.mapState(['projectId', 'userId', 'appVersion', 'devMode']),
    ...Vuex.mapGetters(['appVersion']),
    scopeCollection() {
      const ref = this.$db
        .collection('scopes')
        .where('deleted', '==', 'true')
        .where('project', '==', this.$store.state.projectId);
      return ref;
    },
    dev() {
      const developmentHosts = [
        'localhost:8080',
        'dev.grfn.io',
        'grfn-vue-nuxt.firebaseapp.com',
        'grfn-vue-nuxt.web.app',
      ];

      return (
        process.env.NODE_ENV === 'development' ||
        developmentHosts.some(host => {
          return host === location.host || location.host.includes(host);
        })
      );
    },
  },
  watch: {
    projectId(to, from) {
      if (to !== from) {
        this.$store.dispatch('updateProjectDetails', { db: this.$db });
        this.$store.dispatch('setScopeCollection', { db: this.$db });
      }
    },
  },
  created() {
    this.$store.dispatch('setScopeCollection', { db: this.$db });
    this.$store.commit('setDevMode', this.dev);
  },
  methods: {
    changeScopeCollection() {},
  },
};
</script>

<template>
  <v-app id="grfn-analysis-register">
    <NavigationDrawer />
    <v-main>
      <!-- <keep-alive include="ModelViewer"> -->
      <router-view />
      <!-- </keep-alive> -->
    </v-main>
    <v-footer :inset="footer.inset" app color="white">
      <v-spacer />
      <div class="text-caption">
        GIO v{{ appVersion }}<span v-if="devMode">.beta</span> — GRFN &copy;
        {{ new Date().getFullYear() }}
      </div>
    </v-footer>
  </v-app>
</template>

<style>
body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}

#grfn-analysis-register,
.v-application,
.v-application .caption,
.v-application .title,
.v-application .overline {
  font-family: 'Univers Next W05 Light', 'Univers', 'Jost', 'Roboto',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 200;
}
</style>
