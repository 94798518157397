import { vuexfireMutations } from 'vuexfire';

export default {
  setCurrentEditDocId(state, id) {
    state.currentEditDocId = id;
  },
  setEditMode(state, v) {
    state.scopeEditMode = v;
  },
  setUserError(state, error) {
    state.userError = error;
  },
  setProjectId(state, id) {
    state.projectId = id;
  },
  setProjectApprovalRequired(state, flag) {
    state.projectRequiresApproval = flag ? true : undefined;
  },
  setScopeId(state, id) {
    state.scopeId = id;
  },
  toggleEditMode(state) {
    state.scopeEditMode = !state.scopeEditMode;
  },
  cancelEditMode(state) {
    state.scopeEditMode = false;
    state.currentEditDocId = '';
  },
  updateUser(state, user) {
    if (user && user.claims) {
      state.isGrfn = user.claims.isGrfn;
      state.userId = user.claims.user_id;
    }
  },
  updateUserPhoto(state, url) {
    if (url) state.userPhoto = url;
  },
  updateUserName(state, name) {
    if (name) state.userName = name;
  },
  LOGOUT(state) {
    state.isGrfn = false;
    state.isSignedOut = false;
    state.projectsViewerDb = [];
    state.projectsEditorDb = [];
    state.projectsOwnerDb = [];
    state.userId = '';
    state.scopeFieldValues = [];
    state.currentlySelected = '';
  },
  setGrfnStatus(state, flag) {
    state.isGrfn = flag;
  },
  setAllUsers(state, users) {
    state.allUsers = users;
  },
  setProjectName(state, name) {
    state.projectName = name;
  },
  setProjectsViewable(state, projects) {
    state.setProjectsViewable = projects;
  },
  setScheduledProjects(state, projects) {
    state.scheduledProjectKeys = projects;
  },
  setFocus(state, focus) {
    state.focusDay = focus;
  },
  setProjectSort(state, value) {
    localStorage.setItem('sortProjects', JSON.stringify(value));
    state.sortProjects = value;
  },
  setHideArchived(state, value) {
    localStorage.setItem('hideArchived', JSON.stringify(value));
    state.hideArchived = value;
  },
  setProjectNameDisplay(state, values) {
    localStorage.setItem('projectNameDisplay', JSON.stringify(values));
    state.projectNameDisplay = values;
  },
  initialiseStore(state) {
    if (localStorage.getItem('sortProjects')) {
      state.sortProjects = JSON.parse(localStorage.sortProjects);
    } else {
      localStorage.setItem('sortProjects', JSON.stringify(state.sortProjects));
    }
    if (localStorage.getItem('projectNameDisplay')) {
      state.projectNameDisplay = JSON.parse(localStorage.projectNameDisplay);
    } else {
      localStorage.setItem(
        'projectNameDisplay',
        JSON.stringify(state.projectNameDisplay)
      );
    }
    if (localStorage.getItem('hideArchived')) {
      state.hideArchived = JSON.parse(localStorage.hideArchived);
    } else {
      localStorage.setItem('hideArchived', JSON.stringify(state.hideArchived));
    }
  },
  setAppVersion(state, version) {
    if (version) state.packageVersion = version;
  },
  setDevMode(state, devMode) {
    state.devMode = devMode;
  },
  SET_ACTIVE_MODEL_SET(state, modelSet) {
    console.log('SET_ACTIVE_MODEL_SET', modelSet);
    state.activeModelSet = [...modelSet];
  },
  ...vuexfireMutations,
};
